<template>
  <div class="settingprofile">
    <div id="section-content" class="container container-fluid">
       <div class="bar-back">
      <router-link to="/member/main"><i class="fas fa-chevron-left"></i> หน้าหลัก</router-link>
    </div>
      <div
        class="p-2 m-0 w-100 bg-light  main-content"
        style="min-height: calc((100vh - 140px) - 50px)"
      >
      <app-menu-profile/>
        <div
          class="
            bgwhitealpha
            text-secondary
            shadow-sm
            rounded
            p-2
            px-2
            xtarget
            col-lotto
            d-flex
            flex-row
            mb-1
            pb-0
          "
        >
          <div class="lotto-title">
            <h4>ตั้งค่าบัญชีผู้ใช้</h4>
          </div>
        </div>
        <form
          enctype="multipart/form-data"
          @submit.prevent="onSubmit"
          accept-charset="utf-8"
        >
          <input
            type="hidden"
            name="csrf_token"
            value="994f0f39b33bf16e2f6137d7acaeb03a"
          />
          <input type="hidden" name="updateprofile" value="1" />
          <div class="bgwhitealpha shadow-sm rounded p-2 h-100 xtarget mb-5">
            <div class="row profilesetting">
              <div class="col-12 col-sm-12 col-md-12">
                <b>ชื่อผู้ใช้งาน </b>
                <small> username</small>
                <input
                  type="text"
                  class="form-control"
                  v-bind:value="getUser"
                  readonly=""
                />
              </div>
              <div class="col-12 col-sm-12 col-md-6">
                <b>ชื่อจริง</b>
                <input
                  type="text"
                  class="form-control"
                  value=""
                  v-model="firstName"
                />
              </div>
              <div class="col-12 col-sm-12 col-md-6">
                <b>นามสกุล</b>
                <input
                  type="text"
                  class="form-control"
                  value=""
                  v-model="lastName"
                />
              </div>
              <div class="col-12 col-sm-12 col-md-6">
                <b>ไลน์ ไอดี</b>
                <input
                  type="text"
                  class="form-control"
                  value=""
                  v-model="lineId"
                />
              </div>
              <div class="col-12 col-sm-12 col-md-6">
                <b>วัน/เดือน/ปี เกิด</b>
                <div class="form-row">
                  <div class="form-group col-3">
                    <select
                      name="day"
                      class="form-control"
                      aria-required="true"
                      aria-invalid="false"
                      v-model="day"
                      required=""
                    >
                      <option value="">วัน</option>
                      <option value="01">01</option>
                      <option value="02">02</option>
                      <option value="03">03</option>
                      <option value="04">04</option>
                      <option value="05">05</option>
                      <option value="06">06</option>
                      <option value="07">07</option>
                      <option value="08">08</option>
                      <option value="09">09</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                      <option value="25">25</option>
                      <option value="26">26</option>
                      <option value="27">27</option>
                      <option value="28">28</option>
                      <option value="29">29</option>
                      <option value="30">30</option>
                      <option value="31">31</option>
                    </select>
                  </div>
                  <div class="form-group col-5">
                    <select
                      name="month"
                      class="form-control"
                      aria-required="true"
                      aria-invalid="false"
                      v-model="month"
                      required=""
                    >
                      <option value="">เดือน</option>
                      <option value="01">มกราคม</option>
                      <option value="02">กุมภาพันธ์</option>
                      <option value="03">มีนาคม</option>
                      <option value="04">เมษายน</option>
                      <option value="05">พฤษภาคม</option>
                      <option value="06">มิถุนายน</option>
                      <option value="07">กรกฎาคม</option>
                      <option value="08">สิงหาคม</option>
                      <option value="09">กันยายน</option>
                      <option value="10">ตุลาคม</option>
                      <option value="11">พฤศจิกายน</option>
                      <option value="12">ธันวาคม</option>
                    </select>
                  </div>
                  <div class="form-group col-4">
                    <select
                      name="year"
                      class="form-control"
                      aria-required="true"
                      aria-invalid="false"
                      v-model="year"
                      required=""
                    >
                      <option value="">ปี</option>
                      <option value="2564">2564</option>
                      <option value="2563">2563</option>
                      <option value="2562">2562</option>
                      <option value="2561">2561</option>
                      <option value="2560">2560</option>
                      <option value="2559">2559</option>
                      <option value="2558">2558</option>
                      <option value="2557">2557</option>
                      <option value="2556">2556</option>
                      <option value="2555">2555</option>
                      <option value="2554">2554</option>
                      <option value="2553">2553</option>
                      <option value="2552">2552</option>
                      <option value="2551">2551</option>
                      <option value="2550">2550</option>
                      <option value="2549">2549</option>
                      <option value="2548">2548</option>
                      <option value="2547">2547</option>
                      <option value="2546">2546</option>
                      <option value="2545">2545</option>
                      <option value="2544">2544</option>
                      <option value="2543">2543</option>
                      <option value="2542">2542</option>
                      <option value="2541">2541</option>
                      <option value="2540">2540</option>
                      <option value="2539">2539</option>
                      <option value="2538">2538</option>
                      <option value="2537">2537</option>
                      <option value="2536">2536</option>
                      <option value="2535">2535</option>
                      <option value="2534">2534</option>
                      <option value="2533">2533</option>
                      <option value="2532">2532</option>
                      <option value="2531">2531</option>
                      <option value="2530">2530</option>
                      <option value="2529">2529</option>
                      <option value="2528">2528</option>
                      <option value="2527">2527</option>
                      <option value="2526">2526</option>
                      <option value="2525">2525</option>
                      <option value="2524">2524</option>
                      <option value="2523">2523</option>
                      <option value="2522">2522</option>
                      <option value="2521">2521</option>
                      <option value="2520">2520</option>
                      <option value="2519">2519</option>
                      <option value="2518">2518</option>
                      <option value="2517">2517</option>
                      <option value="2516">2516</option>
                      <option value="2515">2515</option>
                      <option value="2514">2514</option>
                      <option value="2513">2513</option>
                      <option value="2512">2512</option>
                      <option value="2511">2511</option>
                      <option value="2510">2510</option>
                      <option value="2509">2509</option>
                      <option value="2508">2508</option>
                      <option value="2507">2507</option>
                      <option value="2506">2506</option>
                      <option value="2505">2505</option>
                      <option value="2504">2504</option>
                      <option value="2503">2503</option>
                      <option value="2502">2502</option>
                      <option value="2501">2501</option>
                      <option value="2500">2500</option>
                      <option value="2499">2499</option>
                      <option value="2498">2498</option>
                      <option value="2497">2497</option>
                      <option value="2496">2496</option>
                      <option value="2495">2495</option>
                      <option value="2494">2494</option>
                      <option value="2493">2493</option>
                      <option value="2492">2492</option>
                      <option value="2491">2491</option>
                      <option value="2490">2490</option>
                      <option value="2489">2489</option>
                      <option value="2488">2488</option>
                      <option value="2487">2487</option>
                      <option value="2486">2486</option>
                      <option value="2485">2485</option>
                      <option value="2484">2484</option>
                      <option value="2483">2483</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-4">
                <b>อีเมล์</b>
                <input
                  type="email"
                  name="email"
                  id="email"
                  v-model="email"
                  class="form-control"
                  required=""
                  autocomplete="off"
                  style="
                    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAkCAYAAADo6zjiAAAAAXNSR0IArs4c6QAAAbNJREFUWAntV8FqwkAQnaymUkpChB7tKSfxWCie/Yb+gbdeCqGf0YsQ+hU95QNyDoWCF/HkqdeiIaEUqyZ1ArvodrOHxanQOiCzO28y781skKwFW3scPV1/febP69XqarNeNTB2KGs07U3Ttt/Ozp3bh/u7V7muheQf6ftLUWyYDB5yz1ijuPAub2QRDDunJsdGkAO55KYYjl0OUu1VXOzQZ64Tr+IiPXedGI79bQHdbheCIAD0dUY6gV6vB67rAvo6IxVgWVbFy71KBKkAFaEc2xPQarXA931ot9tyHphiPwpJgSbfe54Hw+EQHMfZ/msVEEURjMfjCjbFeG2dFxPo9/sVOSYzxmAwGIjnTDFRQLMQAjQ5pJAQkCQJ5HlekeERxHEsiE0xUUCzEO9AmqYQhiF0Oh2Yz+ewWCzEY6aYKKBZCAGYs1wuYTabKdNNMWWxnaA4gp3Yry5JBZRlWTXDvaozUgGTyQSyLAP0dbb3DtQlmcan0yngT2ekE9ARc+z4AvC7nauh9iouhpcGamJeX8XF8MaClwaeROWRA7nk+tUnyzGvZrKg0/40gdME/t8EvgG0/NOS6v9NHQAAAABJRU5ErkJggg==');
                    background-repeat: no-repeat;
                    background-attachment: scroll;
                    background-size: 16px 18px;
                    background-position: 98% 50%;
                  "
                />
              </div>
              <div class="col-12 col-sm-12 col-md-4">
                <b>เบอร์โทรศัพท์</b>
                <input
                  type="tel"
                  class="form-control"
                  v-model="phone"
                  readonly=""
                />
              </div>
              <div class="w-100 m-3 border-bottom"></div>
              <div class="col-12">
                <h6><i class="fas fa-user-circle"></i> จัดการรหัสผ่าน</h6>
              </div>
              <div class="col-12" id="oldpass">
                <b>รหัสผ่านเดิม</b>
                <input
                  type="password"
                  v-model="presentPassword"
                  name="oldpass_lotto"
                  id="oldpass_val"
                  class="form-control"
                  autocomplete="off"
                  style="
                    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAkCAYAAADo6zjiAAAAAXNSR0IArs4c6QAAAbNJREFUWAntV8FqwkAQnaymUkpChB7tKSfxWCie/Yb+gbdeCqGf0YsQ+hU95QNyDoWCF/HkqdeiIaEUqyZ1ArvodrOHxanQOiCzO28y781skKwFW3scPV1/febP69XqarNeNTB2KGs07U3Ttt/Ozp3bh/u7V7muheQf6ftLUWyYDB5yz1ijuPAub2QRDDunJsdGkAO55KYYjl0OUu1VXOzQZ64Tr+IiPXedGI79bQHdbheCIAD0dUY6gV6vB67rAvo6IxVgWVbFy71KBKkAFaEc2xPQarXA931ot9tyHphiPwpJgSbfe54Hw+EQHMfZ/msVEEURjMfjCjbFeG2dFxPo9/sVOSYzxmAwGIjnTDFRQLMQAjQ5pJAQkCQJ5HlekeERxHEsiE0xUUCzEO9AmqYQhiF0Oh2Yz+ewWCzEY6aYKKBZCAGYs1wuYTabKdNNMWWxnaA4gp3Yry5JBZRlWTXDvaozUgGTyQSyLAP0dbb3DtQlmcan0yngT2ekE9ARc+z4AvC7nauh9iouhpcGamJeX8XF8MaClwaeROWRA7nk+tUnyzGvZrKg0/40gdME/t8EvgG0/NOS6v9NHQAAAABJRU5ErkJggg==');
                    background-repeat: no-repeat;
                    background-attachment: scroll;
                    background-size: 16px 18px;
                    background-position: 98% 50%;
                  "
                />
                <span id="oldpass_text"></span>
              </div>
              <div class="col-12" id="newpass">
                <b>รหัสผ่านใหม่</b>
                <input
                  type="password"
                  name="newpass"
                  id="newpass_val"
                  v-model="newPassword"
                  class="form-control"
                  autocomplete="password"
                  style="
                    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAABKRJREFUWAnNl0tsVGUUxzvTTlslZUaCloZHY6BRFkp9sDBuqgINpaBp02dIDImwKDG6ICQ8jBYlhg0rxUBYEALTpulMgBlqOqHRDSikJkZdGG0CRqAGUuwDovQ1/s7NPTffnTu3zMxGvuT2vP7n8Z3vu+dOi4r+5xUoJH8sFquamZmpTqfTVeIfCARGQ6HQH83NzaP5xsu5gL6+vuVzc3NdJN1Kkhd8Ev1MMYni4uJjra2tt3wwLvUjCxgYGFg8Pj7+MV5dPOUub3/hX0zHIpFId0NDw6Q/jO4tZOzv76+Znp6+AOb5TBw7/YduWC2Hr4J/IhOD/GswGHy7vb39tyw2S+VbAC1/ZXZ29hKoiOE8RrIvaPE5WvyjoS8CX8sRvYPufYpZYtjGS0pKNoD/wdA5bNYCCLaMYMMEWq5IEn8ZDof3P6ql9pF9jp8cma6bFLGeIv5ShdISZUzKzqPIVnISp3l20caTJsaPtwvc3dPTIx06ziZkkyvY0FnoW5l+ng7guAWnpAI5w4MkP6yy0GQy+dTU1JToGm19sqKi4kBjY+PftmwRYn1ErEOq4+i2tLW1DagsNGgKNv+p6tj595nJxUbyOIF38AwipoSfnJyMqZ9SfD8jxlWV5+fnu5VX6iqgt7d3NcFeUiN0n8FbLEOoGkwdgY90dnbu7OjoeE94jG9wd1aZePRp5AOqw+9VMM+qLNRVABXKkLEWzn8S/FtbdAhnuVQE7LdVafBPq04pMYawO0OJ+6XHZkFcBQA0J1xKgyhlB0EChEWGX8RulsgjvOjEBu+5V+icWOSoFawuVwEordluG28oSCmXSs55SGSCHiXhmDzC25ghMHGbdwhJr6sAdpnyQl0FYIyoEX5CeYOuNHg/NhvGiUUxVgfV2VUAxjtqgPecp9oKoE4sNnbX9HcVgMH8nD5nAoWnKM/5ZmKyySRdq3pCmDncR4DxOwVC64eHh0OGLOcur1Vey46xUZ3IcVl5oa4OlJaWXgQwJwZyhUdGRjqE14VtSnk/mokhxnawiwUvsZmsX5u+rgKamprGMDoA5sKhRCLxpDowSpsJ8vpCj2AUPzg4uIiNfKIyNMkH6Z4hF3k+RgTYz6vVAEiKq2bsniZIC0nTtvMVMwBzoBT9tKkTHp8Ak1V8dTrOE+NgJs7VATESTH5WnVAgfHUqlXK6oHpJEI1G9zEZH/Du16leqHyS0UXBNKmeOMf5NvyislJPB8RAFz4g8IuwofLy8k319fUP1EEouw7L7mC3kUTO1nn3sb02MTFxFpsz87FfJuaH4pu5fF+reDz+DEfxkI44Q0ScSbyOpDGe1RqMBN08o+ha0L0JdeKi/6msrGwj98uZMeon1AGaSj+elr9LwK9IkO33n8cN7Hl2vp1N3PcYbUXOBbDz9bwV1/wCmXoS3+B128OPD/l2LLg8l9APXVlZKZfzfDY7ehlQv0PPQDez6zW5JJdYOXdAwHK2dGIv7GH4YtHJIvEOvvunLCHPPzl3QOLKTkl0hPbKaDUvlTU988xtwfMqQBPQ3m/4mf0yBVlDCSr/CRW0CipAMnGzb9XU1NSRvIX7kSgo++Pg9B8wltxxbHKPZgAAAABJRU5ErkJggg==');
                    background-repeat: no-repeat;
                    background-attachment: scroll;
                    background-size: 16px 18px;
                    background-position: 98% 50%;
                  "
                />
                <span id="newpass_text"></span>
              </div>
              <div class="col-12" id="renewpass">
                <b>ยืนยันรหัสผ่านใหม่</b>
                <input
                  type="password"
                  name="renewpass"
                  id="renewpass_val"
                  class="form-control"
                  v-model="confirmPassword"
                  autocomplete="password"
                  style="
                    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAABKRJREFUWAnNl0tsVGUUxzvTTlslZUaCloZHY6BRFkp9sDBuqgINpaBp02dIDImwKDG6ICQ8jBYlhg0rxUBYEALTpulMgBlqOqHRDSikJkZdGG0CRqAGUuwDovQ1/s7NPTffnTu3zMxGvuT2vP7n8Z3vu+dOi4r+5xUoJH8sFquamZmpTqfTVeIfCARGQ6HQH83NzaP5xsu5gL6+vuVzc3NdJN1Kkhd8Ev1MMYni4uJjra2tt3wwLvUjCxgYGFg8Pj7+MV5dPOUub3/hX0zHIpFId0NDw6Q/jO4tZOzv76+Znp6+AOb5TBw7/YduWC2Hr4J/IhOD/GswGHy7vb39tyw2S+VbAC1/ZXZ29hKoiOE8RrIvaPE5WvyjoS8CX8sRvYPufYpZYtjGS0pKNoD/wdA5bNYCCLaMYMMEWq5IEn8ZDof3P6ql9pF9jp8cma6bFLGeIv5ShdISZUzKzqPIVnISp3l20caTJsaPtwvc3dPTIx06ziZkkyvY0FnoW5l+ng7guAWnpAI5w4MkP6yy0GQy+dTU1JToGm19sqKi4kBjY+PftmwRYn1ErEOq4+i2tLW1DagsNGgKNv+p6tj595nJxUbyOIF38AwipoSfnJyMqZ9SfD8jxlWV5+fnu5VX6iqgt7d3NcFeUiN0n8FbLEOoGkwdgY90dnbu7OjoeE94jG9wd1aZePRp5AOqw+9VMM+qLNRVABXKkLEWzn8S/FtbdAhnuVQE7LdVafBPq04pMYawO0OJ+6XHZkFcBQA0J1xKgyhlB0EChEWGX8RulsgjvOjEBu+5V+icWOSoFawuVwEordluG28oSCmXSs55SGSCHiXhmDzC25ghMHGbdwhJr6sAdpnyQl0FYIyoEX5CeYOuNHg/NhvGiUUxVgfV2VUAxjtqgPecp9oKoE4sNnbX9HcVgMH8nD5nAoWnKM/5ZmKyySRdq3pCmDncR4DxOwVC64eHh0OGLOcur1Vey46xUZ3IcVl5oa4OlJaWXgQwJwZyhUdGRjqE14VtSnk/mokhxnawiwUvsZmsX5u+rgKamprGMDoA5sKhRCLxpDowSpsJ8vpCj2AUPzg4uIiNfKIyNMkH6Z4hF3k+RgTYz6vVAEiKq2bsniZIC0nTtvMVMwBzoBT9tKkTHp8Ak1V8dTrOE+NgJs7VATESTH5WnVAgfHUqlXK6oHpJEI1G9zEZH/Du16leqHyS0UXBNKmeOMf5NvyislJPB8RAFz4g8IuwofLy8k319fUP1EEouw7L7mC3kUTO1nn3sb02MTFxFpsz87FfJuaH4pu5fF+reDz+DEfxkI44Q0ScSbyOpDGe1RqMBN08o+ha0L0JdeKi/6msrGwj98uZMeon1AGaSj+elr9LwK9IkO33n8cN7Hl2vp1N3PcYbUXOBbDz9bwV1/wCmXoS3+B128OPD/l2LLg8l9APXVlZKZfzfDY7ehlQv0PPQDez6zW5JJdYOXdAwHK2dGIv7GH4YtHJIvEOvvunLCHPPzl3QOLKTkl0hPbKaDUvlTU988xtwfMqQBPQ3m/4mf0yBVlDCSr/CRW0CipAMnGzb9XU1NSRvIX7kSgo++Pg9B8wltxxbHKPZgAAAABJRU5ErkJggg==');
                    background-repeat: no-repeat;
                    background-attachment: scroll;
                    background-size: 16px 18px;
                    background-position: 98% 50%;
                  "
                />
                <span id="renewpass_text"></span>
              </div>
              <div class="w-100 m-3 border-bottom"></div>
              <div class="col-12">
                <button class="btn btn-primary btn-block" type="submit">
                  <i class="fas fa-save"></i> บันทึก
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import menu_profile from '@/components/menu_profile'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { updateUserProfileService } from '@/services'
export default {
  data: () => {
    return {
      url: require('@/assets/images/userprofile.png'),
      day: null,
      month: null,
      year: null,
      presentPassword: null,
      newPassword: null,
      confirmPassword: null,
      email: null,
      firstName: null,
      lastName: null,
      phone: null,
      lineId: null
    }
  },
  components: {
    'app-menu-profile': menu_profile
  },
  async created () {
    try {
      await this.prepareData()
    } catch (error) {
      this.setLoading(false)
    }
  },
  methods: {
    ...mapActions(['getProfileInfo', 'setLoading', 'openInfoPopup']),
    async onSubmit () {
      try {
        this.setLoading(true)
        const resp = await updateUserProfileService({
          dateOfBirth: moment(
            `${this.day}/${this.month}/${this.year}`,
            'DD/MM/YYYY'
          ),
          ...(this.getProfile.email !== this.email && { email: this.email }),
          ...(this.newPassword &&
            { old_password: this.presentPassword, password: this.newPassword }),
          ...(this.getProfile.first_name !== this.firstName && { first_name: this.firstName }),
          ...(this.getProfile.last_name !== this.lastName && { last_name: this.lastName }),
          ...(this.getProfile.line_id !== this.lineId && { line_id: this.lineId })
        })

        if (resp.status_code === 'SUCCESS') {
          await this.getProfileInfo()
          await this.prepareData()
          await this.openInfoPopup()
        }
        window.scrollTo(0, 0)
        this.setLoading(false)
      } catch (error) {
        this.setLoading(false)
      }
    },
    async prepareData () {
      await this.setLoading(true)
      await this.getProfileInfo()
      this.email = await this.getProfile.email
      this.firstName = await this.getProfile.first_name
      this.lastName = await this.getProfile.last_name
      this.phone = await this.getProfile.phone_number
      this.lineId = await this.getProfile.line_id
      const birthday = await this.getProfile.birthday.toString()
      const spliter = birthday.split('-')
      this.day = await spliter[2]
      this.month = await spliter[1]
      this.year = await spliter[0]
      await this.setLoading(false)
    }
  },
  computed: {
    ...mapGetters(['getProfile']),
    getUser () {
      return this.$store.getters?.getUsername?.toString()
    }
  }
}
</script>
<style>
</style>
